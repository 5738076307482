import React, { useContext, useState, useEffect } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useForm } from 'react-hook-form';
import { makeStyles } from '@naturacosmeticos/natds-web';

import FlexBox from '../../components/flex-box/FlexBox';
import Page from '../../components/page/Page';
import Button from '../../components/button/Button';
import TabContainer from '../../components/tab-container/TabContainer';
import TabItem from '../../components/tab-container/TabItem';
import Icon from '../../components/icon/Icon';
import Card from '../../components/card/Card';
import Typography from '../../components/typography/Typography';
import { I18nContext } from '../../config/i18n';
import ErrorScreen from '../../components/error-screen/ErrorScreen';
import LoadingScreen from '../../components/loading-screen/LoadingScreen';
import DigitalSpaceData from './DigitalSpaceData';
import SalutationData from './SalutationData';
import SocialData from './SocialData';
import Dialog from '../../components/dialog/Dialog';
import DialogContent from '../../components/dialog-content/DialogContent';
import DialogActions from '../../components/dialog-actions/DialogActions';

export const custonStyle = makeStyles((theme) => ({
  headerTable: {
    backgroundColor: theme.color.background,
    zIndex: '1',
  },
  headerTabs: {
    '& .MuiTabs-flexContainer': {
      justifyContent: 'space-between',
    },
  },
}));

const EditDigitalSpace = () => {
  const { translate } = useContext(I18nContext);
  const [tabValue, setTabValue] = useState(0);
  const [openModalError, setOpenModalError] = useState(false);
  const [thereWasAnErrorSaving, setThereWasAnErrorSaving] = useState(false);
  const [openModalNeedToSave, setOpenModalNeedToSave] = useState(false);
  const { saveChanges, getCnData, handleShowcase } = useStoreActions((state) => state.main);
  const {
    error,
    customerNo,
    loading,
    personId,
    loadingFetchItemsShowCase,
  } = useStoreState((state) => state.main);
  const [isMobile] = useState(window.innerWidth < 1080);

  const classes = custonStyle({ isMobile });

  useEffect(() => {
    if (!customerNo) return;
    getCnData({ customerNo });
  }, [customerNo]);

  useEffect(() => {
    if ((personId || customerNo) && !loadingFetchItemsShowCase) {
      handleShowcase({
        start: 0,
      });
    }
  }, [personId, customerNo]);

  const {
    control, formState, handleSubmit, setValue, clearErrors, setError,
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      imputYoutube: '',
      imputWhatsappPhone: '',
      imputFacebook: '',
      imputInstagram: '',
      password: '',
      imputWelcomeMessage: '',
      imputName: '',
    },
  });

  function changeTab(params, value) {
    if (!thereWasAnErrorSaving && Object.keys(formState.errors).length > 0) {
      setOpenModalError(true);
    } else {
      setTabValue(value);
    }
  }

  const checkValuesRequired = () => {
    let hasError = false;
    // eslint-disable-next-line no-restricted-syntax, no-underscore-dangle
    for (const key of Object.keys(control._fields)) {
      // eslint-disable-next-line no-underscore-dangle
      const element = control._fields[key]._f;
      if (element.required === 'required' && !element.value) {
        hasError = true;
      }
    }

    if (hasError) {
      setOpenModalNeedToSave(true);
      setThereWasAnErrorSaving(true);
    } else {
      setThereWasAnErrorSaving(false);
    }
  };

  async function onSubmit(data) {
    if (Object.keys(formState.errors).length === 0) {
      saveChanges(data);
    } else {
      setOpenModalNeedToSave(true);
    }
  }

  function getStatusInput(field, fieldState) {
    if (fieldState.error) {
      return 'error';
    } if (!field.value) {
      return undefined;
    }
    return 'success';
  }

  return (
    <Page>
      {error && <ErrorScreen />}
      {!error && loading && <LoadingScreen />}
      {!error && !loading && (
        <FlexBox alignItems="center" flexDirection="column">
          <FlexBox flexDirection="column" maxWidth="643px">
            <form onSubmit={handleSubmit(onSubmit)}>
              <FlexBox
                justifyContent="space-between"
                alignItems="center"
                position="sticky"
                top="56px"
                height="70px"
                margin="-20px 0px 0px 0px"
                className={classes.headerTable}
              >
                <Typography>{translate('digital_space.page_name')}</Typography>
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  disabled={Object.keys(formState.errors).length}
                  onClick={() => checkValuesRequired()}
                >
                  {translate('digital_space.save_button')}
                </Button>
              </FlexBox>
              <Card p="0" m="20px 0 0 0">
                <TabContainer
                  className={classes.headerTabs}
                  onChange={changeTab}
                  onFocusVisible={function noRefCheck() {}}
                  scrollButtons="off"
                  textColor="inherit"
                  value={tabValue}
                  variant="standard"
                >
                  <TabItem
                    value={0}
                    aria-controls="favorites-tab"
                    icon={<Icon name="outlined-social-myprofile" size="small" />}
                    id="user-data"
                    label={translate('digital_space.data_tab')}
                  />
                  <TabItem
                    value={1}
                    aria-controls="outlet-tab"
                    icon={<Icon name="outlined-content-handcare" size="small" />}
                    id="outlet-tab"
                    label={translate('digital_space.hello_tab')}
                  />
                  <TabItem
                    value={2}
                    aria-controls="outlet-tab"
                    icon={<Icon name="outlined-social-person" size="small" />}
                    id="outlet-tab"
                    label={translate('digital_space.social_tab')}
                  />
                </TabContainer>
                <div style={{ display: tabValue === 0 ? 'initial' : 'none' }}>
                  <DigitalSpaceData
                    setError={setError}
                    control={control}
                    formState={formState}
                    setValue={setValue}
                    clearErrors={clearErrors}
                    getStatusInput={getStatusInput}
                  />
                </div>
                <div style={{ display: tabValue === 1 ? 'initial' : 'none' }}>
                  <SalutationData
                    control={control}
                    formState={formState}
                    setValue={setValue}
                    clearErrors={clearErrors}
                    getStatusInput={getStatusInput}
                  />
                </div>
                <div style={{ display: tabValue === 2 ? 'initial' : 'none' }}>
                  <SocialData
                    control={control}
                    formState={formState}
                    setValue={setValue}
                    clearErrors={clearErrors}
                    getStatusInput={getStatusInput}
                  />
                </div>
              </Card>
            </form>
          </FlexBox>
        </FlexBox>
      )}
      <Dialog
        maxWidth="md"
        open={openModalError}
        onClose={() => setOpenModalError(false)}
        onEscapeKeyDown={() => setOpenModalError(false)}
      >
        <DialogContent>
          <Typography variant="h6">
            {translate('digital_space.has_error_tab')}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={() => setOpenModalError(false)}
            variant="text"
          >
            {translate('forgotPassword.modal.button')}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        maxWidth="md"
        open={openModalNeedToSave}
        onClose={() => setOpenModalNeedToSave(false)}
        onEscapeKeyDown={() => setOpenModalNeedToSave(false)}
      >
        <DialogContent>
          <Typography variant="h6">
            {translate('digital_space.has_need_save_tab')}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={() => setOpenModalNeedToSave(false)}
            variant="text"
          >
            {translate('forgotPassword.modal.button')}
          </Button>
        </DialogActions>
      </Dialog>
    </Page>
  );
};

export default EditDigitalSpace;
